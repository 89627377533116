import React, { useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import InputRange from 'react-input-range';
import { atom, useAtom } from 'jotai';
import { produce } from 'immer';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ZAxis } from 'recharts';
import { debounce } from "debounce";

import 'react-input-range/lib/css/index.css';
import logo from './white_v2.png';

import { runSimulation } from './sim';

let windowWidth = window.innerWidth;

function Graph({ title, data, maxY }) {

    let graphWidth = windowWidth / 4.1;

    return <>
        <h3>{title}</h3>
        <LineChart width={graphWidth} height={graphWidth / 2} data={data}>
            <Tooltip />
            <XAxis dataKey="hour" />
            <YAxis type="number" domain={[0, maxY]} />
            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
            <Line type="monotone" dataKey="value" stroke="#8884d8" isAnimationActive={false} />
        </LineChart>
    </>;
}

function Traffic({ simResults }) {

    let numberOfTrucks = simResults.truckSwapResultsByHour.map(resultsByHour => {
        return resultsByHour.length;
    });

    let data = numberOfTrucks.map((waitTime, index) => {
        return { value: waitTime, hour: MAP[index] };
    })

    return <>
        <Graph title="Trucks per hour" data={data} maxY={100} />
    </>;
}

function MeanWaitTime({ simResults }) {
    let truckSwapResultsByHour = simResults.truckSwapResultsByHour;//[];

    let averageWaitTimes = truckSwapResultsByHour.map(resultsByHour => {
        return resultsByHour.reduce((a, b) => (a + b.waitTime), 0) / resultsByHour.length
    });

    let data = averageWaitTimes.map((waitTime, index) => {
        return { value: waitTime.toFixed(1), hour: MAP[index] };
    })

    return <>
        <Graph title="Mean Wait Time (in minutes)" data={data} maxY={45} />
    </>;
}

function MedianWaitTime({ simResults }) {
    let truckSwapResultsByHour = simResults.truckSwapResultsByHour;//[];

    let medianWaitTimes = truckSwapResultsByHour.map(resultsByHour => {
        if (resultsByHour.length > 0) {
            let sorted = resultsByHour.sort((a, b) => a.waitTime > b.waitTime ? 1 : -1);
            return sorted[Math.floor(sorted.length / 2)].waitTime;
        } return 0;
        //return resultsByHour.reduce((a, b) => (a + b.waitTime), 0) / resultsByHour.length
    });

    let data = medianWaitTimes.map((waitTime, index) => {
        return { value: waitTime, hour: MAP[index] };
    });

    return <>
        <Graph title="Median Wait Time (in minutes)" data={data} maxY={45} />
    </>;
}


function LongestQueueLength({ simResults }) {
    let truckSwapResultsByHour = simResults.truckSwapResultsByHour;//[];

    let longestQueueLengths = truckSwapResultsByHour.map(resultsByHour => {

        if (resultsByHour.length > 0) {
            let sorted = resultsByHour.sort((a, b) => a.queueLengthAtSwap < b.queueLengthAtSwap ? 1 : -1);
            return sorted[0].queueLengthAtSwap;
        } return 0;
        //return resultsByHour.reduce((a, b) => (a + b.waitTime), 0) / resultsByHour.length
    });

    let data = longestQueueLengths.map((waitTime, index) => {
        return { value: waitTime, hour: MAP[index] };
    });

    return <>
        <Graph title="Longest Queue Length" data={data} maxY={30} />
    </>;
}

let MAP = [
    "6AM",
    "7AM",
    "8AM",
    "9AM",
    "10AM",
    "11AM",
    "12PM",
    "1PM",
    "2PM",
    "3PM",
    "4PM",
    "5PM",
    "6PM",
    "7PM",
    "8PM",
    "9PM",
    "10PM",
    "11PM",
    "12AM",
    "1AM",
    "2AM",
    "3AM",
    "4AM",
    "5AM"
];

function LongestWaitTime({ simResults }) {
    let truckSwapResultsByHour = simResults.truckSwapResultsByHour;//[];

    let longestWaitTimes = truckSwapResultsByHour.map(resultsByHour => {
        if (resultsByHour.length > 0) {
            let sorted = resultsByHour.sort((a, b) => a.waitTime < b.waitTime ? 1 : -1);
            return sorted[0].waitTime;
        } else {
            return 0;
        }
        //return resultsByHour.reduce((a, b) => (a + b.waitTime), 0) / resultsByHour.length
    });

    let data = longestWaitTimes.map((waitTime, index) => {
        return { value: waitTime, hour: MAP[index] };
    });


    return <>
        <Graph title="Longest Wait Time (in minutes)" data={data} maxY={45} />
    </>;
}

function EarlySwap({ simResults }) {
    let truckSwapResultsByHour = simResults.truckSwapResultsByHour;//[];

    let sumEarlySwaps = truckSwapResultsByHour.map(resultsByHour => {
        let sum = 0;

        for (let i = 0; i < resultsByHour.length; i++) {
            if (resultsByHour[i].isEarlySwap) {
                sum++;
            }
        }
        return sum;
        //return resultsByHour.reduce((a, b) => (a + b.waitTime), 0) / resultsByHour.length
    });

    let data = sumEarlySwaps.map((earlySwapSum, index) => {
        return { value: earlySwapSum, hour: MAP[index] };
    });


    return <>
        <Graph title="Early Swaps" data={data} maxY={100} />
    </>;
}

function BatterySOC({ simResults }) {
    let data = [];

    let batteryCount = simResults.metrics[0].batterySoc.length;

    for (let i = 0; i < 24; i++) {
        let batterySocAverageAtMidHour = simResults.metrics[i * 60 + 30].batterySoc.reduce((a, b) => a + b) / batteryCount;

        data.push({ value: batterySocAverageAtMidHour.toFixed(3), hour: MAP[i] });
    }

    return <>
        <Graph title="Battery SoC" data={data} maxY={1} />
    </>;
}

function ChargeRates({ simResults }) {
    let data = [];

    for (let i = 0; i < 24; i++) {

        let sum = 0;

        for (let j = 0; j < 60; j++) {
            sum += simResults.metrics[i * 60 + j].batteryChargeRates.reduce((a, b) => a + b);
        }

        //sum 
        //let chargeRateSumAtMidHour = simResults.metrics[i * 60 + 30].batteryChargeRates.reduce((a, b) => a + b);

        //console.log('bcr', simResults.metrics[i * 60].batteryChargeRates)
        data.push({ value: (sum / 60 / simResults.params.chargingCapacity).toFixed(2), hour: MAP[i] });
    }

    return <>
        <Graph title="Charge Rates" data={data} maxY={1} />
    </>;
}

function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}

function createTrafficParameter(dayTrafficByShift) {

    let trafficParamBuffer = new Uint8Array(24 * 60);

    for (let hour = 0; hour < 24; hour++) {
        let hourlyTrafficNumber = dayTrafficByShift[Math.floor(hour / 3)];
        let j = 0;

        while (j < hourlyTrafficNumber) {
            let minuteIndex = getRandomInt(0, 60);
            let lastValue = trafficParamBuffer[hour * 60 + minuteIndex];

            if (lastValue < 4) {
                trafficParamBuffer[hour * 60 + minuteIndex] = lastValue + 1;
                j++;
            }
        }
    }


    return trafficParamBuffer;
}

let shiftTextMap = [
    '6-9AM',
    '9-12PM',
    '12-3PM',
    '3-6PM',
    '6-9PM',
    '9-12AM',
    '12-3AM',
    '3-6AM',
]

function TrafficEditor() {

    let [centralState, set_centralState] = useCentralState();

    function modifyShift(shiftIndex, diff) {
        set_centralState(centralState => {
            centralState.dayTrafficByShift[shiftIndex] += diff;
        })
    }

    return <div>
        <div>Traffic Level</div>
        {centralState.dayTrafficByShift.map((shiftTraffic, index) => {
            return <div style={{ float: "left", padding: 5 }}>

                <button onClick={() => modifyShift(index, -5)}>-</button>

                <button onClick={() => modifyShift(index, 5)}>+</button>
                <div style={{ fontSize: 18, fontWeight: "bold", textAlign: "center" }}>
                    {shiftTraffic}
                </div>
                <div style={{ fontSize: 10, color: "#888" }}>
                    {shiftTextMap[index]}
                </div>

            </div>
        })}
        <div style={{ clear: "both" }}></div>
    </div>
}

// Initialize central state

function createInitialStateFromUrl() {
    let queryString = new URLSearchParams(location.search);

    let state = {
        dayTrafficByShift: (queryString.get('dayTrafficByShift') || '20.20.40.30.20.20.10.10').split('.').map((num) => parseInt(num)),
        batteryCapacity: parseInt(queryString.get('batteryCapacity')) || 32,
        chargingCapacity: parseFloat(queryString.get('chargingCapacity')) || 24.0,
        swapTimeInMinute: parseFloat(queryString.get('swapTimeInMinute')) || 1.0,
        earlySwapValue: parseFloat(queryString.get('earlySwapValue')) || 0.8
    };

    return state;
}


const centralStateAtom = atom(createInitialStateFromUrl());

function useCentralState() {
    let [centralState, set_centralState] = useAtom(centralStateAtom);

    return [centralState, (fn) => {
        set_centralState(produce(fn));
    }]
}

let changeUrl = debounce((url) => {
    history.replaceState({}, '', url);
}, 500);

function useCustomHistoryManagement() {

    let [centralState, set_centralState] = useCentralState();

    function buildUrlFromCentralState(centralState) {

        let url = new URL('http://192.168.100.11:1234/');

        url.searchParams.append('batteryCapacity', centralState.batteryCapacity);
        url.searchParams.append('chargingCapacity', centralState.chargingCapacity);
        url.searchParams.append('swapTimeInMinute', centralState.swapTimeInMinute);
        url.searchParams.append('earlySwapValue', centralState.earlySwapValue);
        url.searchParams.append('dayTrafficByShift', centralState.dayTrafficByShift.join('.'));

        return url.pathname + url.search;
    }

    useEffect(() => {

        changeUrl(buildUrlFromCentralState(centralState));

    }, [centralState]);
}

let unitCapacity = 170;

function App() {

    useCustomHistoryManagement();

    let [centralState, set_centralState] = useCentralState();

    function set_earlySwapValue(value) {
        set_centralState(centralState => {
            centralState.earlySwapValue = value;
        });
    }

    /*
    let [batteryCapacity, set_batteryCapacity] = useState(32);
    let [chargingCapacity, set_chargingCapacity] = useState(24.0);
    let [swapTimeInMinute, set_swapTimeInMinute] = useState(1.0);
    let [earlySwapValue, set_earlySwapValue] = useState(0.8);
    */

    let [trafficParameter, set_trafficParameter] = useState(null);
    let [simResults, set_simResults] = useState(null);

    useEffect(() => {

        set_trafficParameter(createTrafficParameter(centralState.dayTrafficByShift));

    }, [centralState.dayTrafficByShift]);


    useEffect(() => {

        if (!trafficParameter) {
            return;
        }

        let { swapTimeInMinute, batteryCapacity, chargingCapacity, earlySwapValue } = centralState;

        console.log('centralState', centralState)

        console.time('sim');
        let simResults = runSimulation({
            trafficParameter,
            swapTimeInMinute,
            batteryCapacity,
            chargingCapacity,
            earlySwapValue
        });
        console.timeEnd('sim');

        set_simResults(simResults);

    }, [trafficParameter, centralState]);

    if (!simResults) {
        return <div>Loading...</div>;
    }


    let { earlySwapValue, batteryCapacity, chargingCapacity, swapTimeInMinute } = centralState;


    //console.log(simResults.metrics);
    // metrics (by hour):
    // number of trucks
    // mean queue length
    // p99 queue length
    // mean wait time
    // median wait time
    // p90 wait time
    // p99 wait time
    return <>
        <div style={{ background: "#111", padding: 10 }}>
            <img src={logo} width={70} />
            <span style={{ fontWeight: "bold", color: "#000", marginLeft: 10, marginTop: -10, display: "inline-block" }}>SwapNet Simulator</span>
        </div>
        <div style={{ background: "#fff", padding: 10 }}>
            <div style={{ float: "left", width: 300 }}>

                <TrafficEditor />
                <div style={{ width: 250, marginTop: 30 }}>

                    <InputRange
                        maxValue={64}
                        minValue={2}
                        value={batteryCapacity}
                        //onChange={value => set_batteryCapacity(value)} />
                        onChange={value => set_centralState(centralState => {
                            centralState.batteryCapacity = value;
                        })} />
                    Battery Capacity
                </div>
                <div style={{ width: 250, marginTop: 30 }}>

                    <InputRange
                        maxValue={64}
                        minValue={2}
                        value={chargingCapacity}
                        onChange={value => set_centralState(centralState => {
                            centralState.chargingCapacity = value;
                        })} />
                    Charging Capacity
                </div>
                <div style={{ width: 250, marginTop: 30 }}>

                    <InputRange
                        maxValue={2}
                        minValue={0.5}
                        step={0.25}
                        value={swapTimeInMinute}
                        onChange={value => set_centralState(centralState => {
                            centralState.swapTimeInMinute = value;
                        })} />
                    Swap Time: {swapTimeInMinute} min

                </div>
                <div style={{ width: 250, marginTop: 30 }}>

                    Early Swaps
                    <form>
                        <input type="radio" checked={earlySwapValue >= 0.8} onChange={() => set_earlySwapValue(0.8)} />
                        <label for="male">N/A</label>

                        <input type="radio" checked={earlySwapValue == 0.7} onChange={() => set_earlySwapValue(0.7)} />
                        <label>70%</label>

                        <input type="radio" checked={earlySwapValue == 0.6} onChange={() => set_earlySwapValue(0.6)} />
                        <label>60%</label>

                        <input type="radio" checked={earlySwapValue == 0.5} onChange={() => set_earlySwapValue(0.5)} />
                        <label>50%</label>

                        <input type="radio" checked={earlySwapValue == 0.4} onChange={() => set_earlySwapValue(0.4)} />
                        <label>40%</label>

                    </form>

                </div>

                <div>
                    <input type="radio" checked={true} onChange={() => set_earlySwapValue(0.4)} />
                    <label>Single-Queue</label>
                    <input type="radio" checked={false} onChange={() => set_earlySwapValue(0.4)} />
                    <label>Double-Queue</label>
                </div>

                <hr />

                <h4>Total Battery Capacity: {batteryCapacity * unitCapacity}kWh (${batteryCapacity * unitCapacity * 80})</h4>
                <h4>Total Charging Capacity: {chargingCapacity * unitCapacity / 1000} MW (${(chargingCapacity * unitCapacity / 22 * 1500).toFixed(0)})</h4>

                <hr />
            </div>
            <div style={{ float: "left", width: 1400 }}>
                <div style={{ float: "left" }}><Traffic simResults={simResults} /></div>
                <div style={{ float: "left" }}><ChargeRates simResults={simResults} /></div>
                <div style={{ float: "left" }}><BatterySOC simResults={simResults} /></div>
                <div style={{ float: "left" }}><LongestQueueLength simResults={simResults} /></div>
                <div style={{ float: "left" }}><MedianWaitTime simResults={simResults} /></div>
                <div style={{ float: "left" }}><EarlySwap simResults={simResults} /></div>
                <div style={{ float: "left" }}><MeanWaitTime simResults={simResults} /></div>
                <div style={{ float: "left" }}><LongestWaitTime simResults={simResults} /></div>
            </div>
        </div>
    </>
}

ReactDOM.createRoot(window.root).render(<App />);
