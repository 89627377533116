function calculateBatteryChargeRates(batteryCapacity, maxChargingCapacity, batteryStates) {

    let batteryCount = batteryStates.length;
    let batteryChargeRates = new Array(batteryCount).fill(0.0);

    // 1. Try to allocate 3C to top-4-highest-SOC (under 80%) batteries. (tier 1)
    // 2. Try to allocate 2C to the next 8. (tier 2)
    // 3. Try to allocate 1.5C to the rest.
    // 3. If still spare capacity, trickle charge the batteries.
    let sortedBatteryStates = batteryStates.map(a => { return { ...a } });
    sortedBatteryStates.sort((a, b) => a.soc < b.soc ? 1 : -1);

    let highSocBatteryCount = sortedBatteryStates.filter(battery => battery.soc >= 0.8).length;
    let lowSocBatteryFirstIndex = highSocBatteryCount; //sortedBatteryStates.findIndex(battery => battery.soc < 0.8);
    let lowSocBatteryChargeCounter = 0;

    // Tier 1 is 25% of the batteries
    // Tier 2 is the next 50%
    let tier1Count = parseInt(batteryCapacity / 4);
    let tier2Count = parseInt(batteryCapacity / 2);

    let availableChargeCapacity = maxChargingCapacity;

    // Step 1
    for (let i = lowSocBatteryFirstIndex; i < batteryCount && availableChargeCapacity > 0.0; i++) {
        let chargeForBattery = 0;
        let chargeToAllocate = lowSocBatteryChargeCounter < tier1Count ? 3.0 : lowSocBatteryChargeCounter < (tier1Count + tier2Count) ? 2.0 : 1.5;

        if (availableChargeCapacity > chargeToAllocate) {
            chargeForBattery = chargeToAllocate;
        } else {
            chargeForBattery = availableChargeCapacity;
        }

        batteryChargeRates[i] = chargeForBattery;
        availableChargeCapacity -= chargeForBattery;

        lowSocBatteryChargeCounter++;
    }
    //}

    // Give trickle charge to >=80% (but not full) batteries 
    let nonFullHighSocBatteryCount = sortedBatteryStates.filter(battery => battery.soc >= 0.8 && battery.soc < 0.999).length;
    let availableTrickleCharge = Math.min(0.1, availableChargeCapacity / nonFullHighSocBatteryCount);

    for (let i = 0; i < nonFullHighSocBatteryCount && availableTrickleCharge > 0.0; i++) {

        if (sortedBatteryStates[i].soc >= 0.999) {
            continue;
        }

        batteryChargeRates[i] = availableTrickleCharge;
        availableChargeCapacity -= availableTrickleCharge;
    }

    // Return charge rates to the original sorting position
    let unsortedChargeRates = new Array(batteryCount).fill(0.0);

    for (let i = 0; i < batteryCount; i++) {
        let batteryIndex = sortedBatteryStates[i].id;

        unsortedChargeRates[batteryIndex] = batteryChargeRates[i];
    }

    return unsortedChargeRates;
}

export function runSimulation(params) {

    let { trafficParameter, swapTimeInMinute, batteryCapacity, chargingCapacity, earlySwapValue } = params;

    let swapQueue = [];
    let truckSwapResults = [];

    let batteryStates = [];
    // Decide on battery charge rates
    //let batteryChargeRates = [];
    let batteryCount = batteryCapacity;

    let metrics = [];

    for (let i = 0; i < batteryCount; i++) {
        //batteryChargeRates.push(0.0);
        batteryStates.push({ id: i, soc: 0.8 });
    }

    ////////////////////////////
    let timestepMax = 24 * 60 * 4; // per-15 second timestep
    let truckCount = 0;

    let activeSwappingTruck = null;
    let swapTimeLeft = 0;
    let swappingBatteryIndex = 0;

    function allocateBatteryToNewSwap() {

        for (let i = 0; i < batteryCount; i++) {
            if (batteryStates[i].soc >= 0.8) {
                swappingBatteryIndex = i;
                return true;
            }
        }

        // enable early-swap on busy queue
        if (earlySwapValue < 0.8 && swapQueue.length > 5) {
            for (let i = 0; i < batteryCount; i++) {
                if (batteryStates[i].soc >= earlySwapValue) {
                    swappingBatteryIndex = i;
                    console.log('early swap!');
                    return true;
                }
            }
        }

        //console.log('no battery @', swapQueue.length, batteryStates.map(state => state.soc));

        return false;
    }

    function finishBatterySwap() {
        batteryStates[swappingBatteryIndex].soc = 0.1; // assume battery state given back by the truck is 10%
    }

    function runBatteryCharge() {

        let batteryChargeRates = calculateBatteryChargeRates(batteryCapacity, chargingCapacity, batteryStates);

        // Apply charge to batteries
        for (let i = 0; i < batteryCount; i++) {
            batteryStates[i].soc += (batteryChargeRates[i] / 60 / 4);
        }

        return batteryChargeRates;
    }

    for (let timestep = 0; timestep < timestepMax; timestep++) {
        let currentHour = parseInt(timestep / 60 / 4);

        // check if there's a new truck
        let minuteIndex = Math.floor(timestep / 4);
        let trafficParameterAtMinute = trafficParameter[minuteIndex];

        let shouldGenerateNewTrucks = timestep % 4 == 0;

        if (shouldGenerateNewTrucks && trafficParameterAtMinute > 0) {
            for (let i = 0; i < trafficParameterAtMinute; i++) {
                truckCount++;

                swapQueue.push({
                    truckId: truckCount,
                    entryHour: currentHour,
                    waitTime: 0
                });
            }
        }

        if (!activeSwappingTruck && swapQueue.length > 0) {
            let allocateSuccess = allocateBatteryToNewSwap();

            if (allocateSuccess) {
                activeSwappingTruck = swapQueue.shift();
                swapTimeLeft = Math.floor(swapTimeInMinute * 4); // 1 minute * 15-second increment    
            }
        }

        for (let i = 0; i < swapQueue.length; i++) {
            swapQueue[i].waitTime++;
        }

        if (activeSwappingTruck) {
            swapTimeLeft--;

            if (swapTimeLeft == 0) {
                let isEarlySwap = batteryStates[swappingBatteryIndex].soc < 0.8;

                finishBatterySwap();

                truckSwapResults.push({
                    waitTime: activeSwappingTruck.waitTime / 4, // in minutes
                    currentHour: activeSwappingTruck.entryHour,
                    isEarlySwap: isEarlySwap,
                    queueLengthAtSwap: swapQueue.length
                });

                activeSwappingTruck = null;
            }
        }

        let batteryChargeRates = runBatteryCharge();

        // Create metrics
        if (timestep % 4 == 0) {
            metrics.push({
                batteryChargeRates: [...batteryChargeRates],
                batterySoc: batteryStates.map(state => state.soc)
            });
        }
    }

    // post-process results
    let truckSwapResultsByHour = [];

    for (let i = 0; i < 24; i++) {
        truckSwapResultsByHour.push([]);
    }

    truckSwapResults.forEach(result => truckSwapResultsByHour[result.currentHour].push(result));

    // output is list of results list by hour
    // [ hour0: [], hour1: [], ...]
    return {
        params,
        truckSwapResultsByHour,
        metrics
    };
}
